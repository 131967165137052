.App {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #117197;
  margin: 20px 0;
}

.pulse-button {
  display: block;
  font-size: 10vw;
  line-height: 10vw;
  height: 50vw;
  width: 50vw;
  border-radius: 50%;
  border: 1vw solid rgb(156, 24, 24);
  background-color: rgb(199, 75, 75);
  color: white;
  margin: 2vh 0;
  transition: transform 0.2s ease-in-out;
}

.pulse-button:active {
  transform: scale(1.05);
}

.pulse-button .small-text {
  font-size: 5vw;
  line-height: normal;
  padding: 0 2vw;
}

.pulse-button .xsmall-text {
  font-size: 4vw;
  line-height: normal;
  margin-top: 2vw;
  padding: 0 2vw;
}

.help-button {
  background-color: rgb(179, 55, 55);
  border: none;
  color: white;
  font-size: inherit;
  border-radius: 50%;
  margin-left: 0.5rem;
  cursor: pointer;
}

.help-button.active {
  background-color: rgb(212, 102, 102);
}

.help-container {
  text-align: left;
  padding-right: 5vw;
  list-style-type: decimal;
}

.language-switch button {
  border: none;
  font-size: 4vw;
  padding: 1vw;
}

.language-switch button.active {
  background-color: rgb(179, 55, 55);
  color: white;
}

.disclaimer {
  margin-top: 2vw;
  padding: 0 1vw;
}
